<template>
  <div>
    <b-row class="pb-1">
      <b-col cols="12" md="8" lg="8" class="h-100">
        <b-card no-body class="p-1 mb-0">
          <div class="d-flex justify-content-between align-items-start">
            <div class="d-flex">
              <feather-icon icon="CalendarIcon" size="19"/>
              <h5 class="mb-0 ml-50">Toplantı Bilgileri</h5>
            </div>
            <b-button-toolbar justify>
              <b-button-group>
                <b-button variant="primary" type="button" size="sm" @click="onSubmit(true)" v-if="editDataForm">
                  <span class="align-middle" role="button"> Kaydet ve Paylaş</span>
                </b-button>
                <b-button variant="outline-primary" type="button" size="sm" @click="onSubmit(false)" v-if="editDataForm">
                  <span class="align-middle" role="button"> Kaydet</span>
                </b-button>
                <b-button variant="outline-info" size="sm" @click="documentModalShow=true">
                  <span class="align-middle" role="button"> Dökümanlar</span>
                </b-button>
                <b-button :to="{ name: 'meeting-list' }" variant="outline-secondary" size="sm">
                  <span class="align-middle" role="button"> {{ editDataForm ? 'Vazgeç' : 'Kapat' }}</span>
                </b-button>
              </b-button-group>
            </b-button-toolbar>
          </div>
          <b-row class="mt-1 mb-0">
            <b-col cols="12" md="7" lg="7">
              <table class="mt-2 mt-xl-0 w-100">
                <tr class="border-bottom">
                  <th class="pb-50"><span class="font-weight-bold">Konu</span></th>
                  <td class="pb-50">{{ dataInfo.title }}</td>
                </tr>
                <tr class="border-bottom">
                  <th class="py-50"><span class="font-weight-bold">Kategori</span></th>
                  <td class="py-50">{{ dataInfo.meetingCategoryName }}</td>
                </tr>
                <tr class="border-bottom">
                  <th class="py-50"><span class="font-weight-bold">İşyeri</span></th>
                  <td class="py-50">{{ dataInfo.workplaceName }}</td>
                </tr>
                <tr class="border-bottom">
                  <th class="py-50"><span class="font-weight-bold">Toplantı Salonu</span></th>
                  <td class="py-50">{{ dataInfo.meetingRoomName }}</td>
                </tr>
                <tr class="border-bottom" v-if="!busy">
                  <th class="py-50"><span class="font-weight-bold">Toplantı İçeriği</span></th>
                  <td class="py-50"><span v-html="dataInfo.description!==null && dataInfo.description!== '' ? dataInfo.description.replace(/\n/g, '<br>') : ''"></span></td>
                </tr>
              </table>
            </b-col>
            <b-col cols="12" md="5" lg="5">
              <table class="mt-2 mt-xl-0 w-100">
                <tr class="border-bottom">
                  <th class="py-50"><span class="font-weight-bold">Başlangıç Zamanı</span></th>
                  <td class="py-50">
                    <date-time-column-table :data="dataInfo.startDate"></date-time-column-table>
                  </td>
                </tr>
                <tr class="border-bottom">
                  <th class="py-50"><span class="font-weight-bold">Bitiş Zamanı</span></th>
                  <td class="py-50">
                    <date-time-column-table :data="dataInfo.endDate"></date-time-column-table>
                  </td>
                </tr>
                <tr class="border-bottom">
                  <th class="py-50"><span class="font-weight-bold">Durum</span></th>
                  <td class="py-50">{{ dataInfo.meetingStatusName }}</td>
                </tr>
                <tr class="border-bottom">
                  <th class="py-50"><span class="font-weight-bold">Toplantı Tipi</span></th>
                  <td class="py-50">{{ dataInfo.meetingTypeName }}</td>
                </tr>
                <tr class="border-bottom">
                  <th class="py-50"><span class="font-weight-bold">Hatırlatma</span></th>
                  <td class="py-50">{{ dataInfo.meetingRemindingTypeName }}</td>
                </tr>
              </table>
            </b-col>
          </b-row>
        </b-card>
        <b-card no-body class="p-1 mb-0 mt-1 mb-1">
          <div class="d-flex justify-content-between align-items-start">
            <div class="d-flex">
              <feather-icon icon="ClipboardIcon" size="19"/>
              <h5 class="mb-0 ml-50">Tutanak Bilgileri</h5>
            </div>
          </div>
          <b-row class="mt-1 mb-0">
            <b-col cols="12" md="12" lg="12">
              <b-form-group>
                <b-form-textarea v-model="dataInfo.reportDescription" rows="10" placeholder="Tutanak Bilgileri..." :disabled="!editDataForm"/>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="12" lg="12" v-if="editDataForm">
              <validation-provider #default="validationContext" name="Döküman Ekle">
                <b-form-group label="Döküman Ekle">
                  <b-form-file id="meetingFiles" name="meetingFiles" placeholder="Döküman seçimi yapınız..." drop-placeholder="Dökümanlarınızı sürükleyerek buraya bırakabilirsiniz..." multiple/>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" md="4" lg="4">
        <b-card no-body class="h-100 p-1 scroll-flow">
          <div class="d-flex justify-content-between align-items-start mb-1">
            <div class="d-flex">
              <feather-icon icon="GitMergeIcon" size="16"/>
              <h5 class="mb-0 ml-50">Toplantı Akışı</h5>
            </div>
          </div>

          <app-timeline v-if="dataInfo.flows!= null && dataInfo.flows.length > 0">
            <app-timeline-item :title="flow.title" :subtitle="(flow.fullName !== null && flow.fullName !== ' ' ? (flow.fullName + ` - ` + flow.position) : '')" :time="formatTime(flow.startDate) + `-` + formatTime(flow.endDate)" variant="success" v-for="(flow, index) in dataInfo.flows" :key="index"/>
          </app-timeline>

          <div class="empty-card border h-100 py-1" v-if="dataInfo.flows == null || dataInfo.flows.length === 0">
            <b-media no-body>
              <b-media-aside class="mb-1">
                <b-avatar size="70" variant="light-warning">
                  <feather-icon size="30" icon="GitMergeIcon"/>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
              </b-media-body>
            </b-media>
            <b-card-text class="font-small-3 mb-0">
              Akış bilgisi bulunamadı...
            </b-card-text>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="pb-1">
      <b-col cols="12" md="4" lg="4">
        <b-card no-body class="h-100 p-1 mb-0">
          <div class="d-flex">
            <feather-icon icon="UsersIcon" size="16"/>
            <h5 class="mb-0 ml-50">Katılımcılar</h5>
          </div>
          <div class="d-flex justify-content-between mb-1 mt-1 mr-5">
            <v-select v-model="newParticipantUserId" :clearable="false" :options="userOptions" label="label" :reduce="val => val.value" class="select-size-sm" v-if="editDataForm">
              <template #option="{ avatar, label }">
                <b-avatar size="sm" :src="getApiFile(avatar)" :text="(label!=null && !busy ? avatarText(label) : '')"/>
                <span class="ml-50 align-middle"> {{ label }}</span>
              </template>
              <template #selected-option="{ avatar, label }">
                <b-avatar size="sm" class="border border-white" :src="getApiFile(avatar)" :text="(label!=null && !busy ? avatarText(label) : '')"/>
                <span class="ml-50 align-middle"> {{ label }}</span>
              </template>
            </v-select>
            <b-button variant="primary" size="sm" class="ml-1" @click="addParticipant('PARTICIPANT')" v-if="editDataForm">
              <feather-icon icon="PlusSquareIcon" size="16"/>
            </b-button>
          </div>
          <b-list-group v-if="participantUsers.length > 0">
            <b-list-group-item v-for="(user, index) in participantUsers" :key="index">
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex justify-content-start align-items-center">
                  <b-avatar class="mr-1" :src="getApiFile(user.profilePicture)" :text="avatarText(user.firstName + ' ' + user.lastName)" :variant="`light-primary`" size="35"/>
                  <div class="profile-user-info">
                    <h6 class="mb-0">{{ user.firstName + ' ' + user.lastName }}</h6>
                    <div class="d-flex justify-content-start align-items-start flex-column">
                      <small class="text-muted">{{ user.title }}</small>
                    </div>
                    <div class="my-auto" v-if="user.meetingParticipantTypeId === 'ORGANIZER'">
                      <b-badge variant="light-info">Moderatör</b-badge>
                    </div>
                  </div>
                </div>
                <div>
                  <b-badge variant="light-success" class="mr-1" v-if="user.participation">Katıldı</b-badge>
                  <b-badge variant="light-danger" class="mr-1" v-if="!user.participation">Katılmadı</b-badge>
                  <b-button variant="gradient-warning" class="btn-icon" size="sm" @click="removeParticipant(user)" v-if="editDataForm">
                    <feather-icon size="10" icon="CheckCircleIcon"/>
                  </b-button>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
          <div class="empty-card border h-100 py-1" v-if="participantUsers.length === 0">
            <b-media no-body>
              <b-media-aside class="mb-1">
                <b-avatar size="70" variant="light-warning">
                  <feather-icon size="30" icon="UsersIcon"/>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
              </b-media-body>
            </b-media>
            <b-card-text class="font-small-3 mb-0">
              Henüz kullanıcı eklenmemiş...
            </b-card-text>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12" md="4" lg="4">
        <b-card no-body class="h-100 p-1 mb-0">
          <div class="d-flex">
            <feather-icon icon="UsersIcon" size="16"/>
            <h5 class="mb-0 ml-50">Bilgililer</h5>
          </div>
          <div class="d-flex justify-content-between mb-1 mt-1 mr-5">
            <v-select v-model="newInfoUserId" :clearable="false" :options="userOptions" label="label" :reduce="val => val.value" class="select-size-sm" v-if="editDataForm">
              <template #option="{ avatar, label }">
                <b-avatar size="sm" :src="getApiFile(avatar)" :text="(label!=null && !busy ? avatarText(label) : '')"/>
                <span class="ml-50 align-middle"> {{ label }}</span>
              </template>
              <template #selected-option="{ avatar, label }">
                <b-avatar size="sm" class="border border-white" :src="getApiFile(avatar)" :text="(label!=null && !busy ? avatarText(label) : '')"/>
                <span class="ml-50 align-middle"> {{ label }}</span>
              </template>
            </v-select>
            <b-button variant="primary" size="sm" class="ml-1" @click="addParticipant('INFORMATION')" v-if="editDataForm">
              <feather-icon icon="PlusSquareIcon" size="16"/>
            </b-button>
          </div>
          <b-list-group v-if="informationUsers.length > 0">
            <b-list-group-item v-for="(user, index) in informationUsers" :key="index">
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex justify-content-start align-items-center">
                  <b-avatar class="mr-1" :src="getApiFile(user.profilePicture)" :text="avatarText(user.firstName + ' ' + user.lastName)" :variant="`light-primary`" size="35"/>
                  <div class="profile-user-info">
                    <h6 class="mb-0">{{ user.firstName + ' ' + user.lastName }}</h6>
                    <div class="d-flex justify-content-start align-items-start flex-column">
                      <small class="text-muted">{{ user.title }}</small>
                    </div>
                  </div>
                </div>
                <div>
                  <b-badge variant="light-success" class="mr-1" v-if="user.participation">Katıldı</b-badge>
                  <b-badge variant="light-danger" class="mr-1" v-if="!user.participation">Katılmadı</b-badge>
                  <b-button variant="gradient-warning" class="btn-icon" size="sm" @click="removeParticipant(user)" v-if="editDataForm">
                    <feather-icon size="10" icon="CheckCircleIcon"/>
                  </b-button>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
          <div class="empty-card border h-100 py-1" v-if="informationUsers.length === 0">
            <b-media no-body>
              <b-media-aside class="mb-1">
                <b-avatar size="70" variant="light-warning">
                  <feather-icon size="30" icon="UsersIcon"/>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
              </b-media-body>
            </b-media>
            <b-card-text class="font-small-3 mb-0">
              Henüz kullanıcı eklenmemiş...
            </b-card-text>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12" md="4" lg="4">
        <b-card no-body class="h-100 p-1 mb-0">
          <div class="d-flex justify-content-between align-items-start mb-1">
            <div class="d-flex">
              <feather-icon icon="UsersIcon" size="16"/>
              <h5 class="mb-0 ml-50">Misafirler</h5>
            </div>
            <b-button variant="primary" size="sm" @click="guestNewModal" v-if="editDataForm">
              <feather-icon icon="PlusSquareIcon" size="15"/>
            </b-button>
          </div>
          <b-list-group v-if="guestUsers.length > 0">
            <b-list-group-item v-for="(user, index) in guestUsers" :key="index">
              <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex justify-content-start align-items-center">
                  <b-avatar class="mr-1" :text="avatarText(user.firstName + ' ' + user.lastName)" :variant="`light-primary`" size="35"/>
                  <div class="profile-user-info">
                    <h6 class="mb-0">{{ user.firstName + ' ' + user.lastName }}</h6>
                    <div class="d-flex justify-content-start align-items-start flex-column">
                      <small class="text-muted">{{ user.title }}</small>
                    </div>
                  </div>
                </div>
                <div>
                  <b-badge variant="light-success" class="mr-1" v-if="user.participation">Katıldı</b-badge>
                  <b-badge variant="light-danger" class="mr-1" v-if="!user.participation">Katılmadı</b-badge>
                  <b-button variant="gradient-warning" class="btn-icon" size="sm" @click="removeParticipant(user)" v-if="editDataForm">
                    <feather-icon size="10" icon="CheckCircleIcon"/>
                  </b-button>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
          <div class="empty-card border h-100 py-1" v-if="guestUsers.length === 0">
            <b-media no-body>
              <b-media-aside class="mb-1">
                <b-avatar size="70" variant="light-warning">
                  <feather-icon size="30" icon="UsersIcon"/>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
              </b-media-body>
            </b-media>
            <b-card-text class="font-small-3 mb-0">
              Henüz kullanıcı eklenmemiş...
            </b-card-text>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <Overlay :busy="busy"></Overlay>
    <b-modal v-model="guestModalShow" title="Misafir Bilgileri" ok-title="Kaydet" cancel-title="Vazgeç" cancel-variant="outline-secondary" @show="guestResetModal" @hidden="guestResetModal" @ok="guestHandleModal" no-close-on-esc no-close-on-backdrop>
      <form ref="form" @submit.stop.prevent="guestSubmitModal">
        <b-form-group label="* İsim">
          <b-form-input trim placeholder="İsim" v-model="guestInfo.firstName"/>
        </b-form-group>
        <b-form-group label="* Soyisim">
          <b-form-input trim placeholder="Soyisim" v-model="guestInfo.lastName"/>
        </b-form-group>
        <b-form-group label="* Ünvan">
          <b-form-input trim placeholder="Ünvan" v-model="guestInfo.title"/>
        </b-form-group>
        <b-form-group label="Şirket">
          <b-form-input trim placeholder="Şirket" v-model="guestInfo.company"/>
        </b-form-group>
        <b-form-group label="Telefon">
          <b-form-input trim placeholder="Telefon" v-model="guestInfo.phone"/>
        </b-form-group>
        <b-form-group label="Email Adres">
          <b-form-input trim placeholder="Email Adres" v-model="guestInfo.eMail"/>
        </b-form-group>
      </form>
    </b-modal>
    <b-modal v-model="documentModalShow" ref="document-modal" title="Dökümanlar" ok-title="Kapat" size="lg" @ok="documentHandleModal" centered no-close-on-backdrop no-close-on-esc ok-only>
      <b-table
          ref="refListTable"
          primary-key="id"
          empty-text="Kayıt bulunamadı"
          :class="`position-relative table-sticky`"
          :items="dataInfo.documents"
          :fields="documentTableColumns"
          responsive striped bordered hover show-empty>

        <template #cell(filePath)="data">
          <div class="text-nowrap">
            <feather-icon :id="`row-${data.item.id}-prev`" icon="EyeIcon" size="16" @click="previewFile(data.value)" class="mx-1"/>
          </div>
        </template>

        <template #cell(createdOn)="data">
          <date-time-column-table :data="data.value"></date-time-column-table>
        </template>

        <template #cell(isReport)="data">
          <boolean-column-table :data="data.value"></boolean-column-table>
        </template>

      </b-table>
    </b-modal>
  </div>
</template>

<script>
import {BAvatar, BBadge, BButton, BButtonGroup, BButtonToolbar, BCard, BCardText, BCol, BDropdown, BDropdownItem, BForm, BFormFile, BFormGroup, BFormInput, BFormInvalidFeedback, BFormTags, BFormTextarea, BFormTimepicker, BListGroup, BListGroupItem, BMedia, BMediaAside, BMediaBody, BModal, BRow, BTable} from 'bootstrap-vue'
import {computed, onUnmounted, ref, watch} from '@vue/composition-api'
import vSelect from 'vue-select'
import store from "@/store"
import storeModule from "@/views/meeting/meeting/store"
import userModule from "@/views/organization/employee/store"
import router from '@/router'
import {useToast} from 'vue-toastification/composition'
import {avatarText, formatTime, getApiFile, statusOptions, yesNoOptions} from "@core/utils/filter"
import Overlay from "@/components/Overlay.vue"
import ActionButtons from "@/components/Form/ActionButtons.vue"
import {toastMessage} from "@core/utils/utils"
import Vue from "vue"
import flatpickr from "vue-flatpickr-component/src/component"
import {ActiveColumnTable, DateTimeColumnTable} from "@/components/Table";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import {ValidationProvider} from "vee-validate";
import BooleanColumnTable from "@/components/Table/BooleanColumnTable.vue";

export default {
  components: {
    BooleanColumnTable,
    ValidationProvider,
    AppTimelineItem,
    AppTimeline,
    ActiveColumnTable, DateTimeColumnTable,
    BDropdownItem, BButtonGroup, BDropdown, BButtonToolbar,
    BFormTimepicker,
    BBadge,
    BFormFile,
    BCardText,
    BMediaBody,
    BMediaAside,
    BMedia,
    BListGroupItem,
    BListGroup,
    BFormTags,
    BAvatar,
    BModal,
    BTable,
    BFormTextarea,
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BFormInvalidFeedback,

    flatpickr,
    ActionButtons,
    Overlay,
    vSelect,
  },
  setup() {
    const STORE_MODULE_NAME = 'store'
    const STORE_USER_MODULE_NAME = 'store-user'

    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
      store.registerModule(STORE_USER_MODULE_NAME, userModule)
    }
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) {
        store.unregisterModule(STORE_MODULE_NAME)
        store.unregisterModule(STORE_USER_MODULE_NAME)
      }
    })

    const toast = useToast()
    const busy = ref(false)

    const newParticipantUserId = ref(null)
    const newInfoUserId = ref(null)
    const editDataForm = ref(true)
    const documentModalShow = ref(false)
    const documentTableColumns = ref([])

    const dataInfo = ref({
      id: null,
      reportDescription: '',
      participants: [],
    })

    const userOptions = ref([])
    busy.value = true
    store.dispatch('store-user/fetchItems', {sortColumn: 'firstName', sortDesc: false, isActive: true}).then(response => {
      response.data.data.forEach((value, index) => {
        if (!userOptions.value.some(e => e.value === value.id)) {
          userOptions.value.push({label: value.firstName + ' ' + value.lastName, value: value.id, avatar: value.profilePicture, positionName: value.positionName, firstName: value.firstName, lastName: value.lastName})
        }
      })
    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
    }).finally(message => {
      busy.value = false
    })

    const onSubmit = (share) => {
      if (dataInfo.value.reportDescription === null || dataInfo.value.reportDescription === '') {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Tutanak bilgisi boş geçilemez!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      busy.value = true
      dataInfo.value.share = share
      store.dispatch('store/reportItem', dataInfo.value).then(response => {

        if (editDataForm.value && document.getElementById('meetingFiles').files.length > 0) {
          const documents = document.getElementById('meetingFiles').files
          const formData = new FormData();
          formData.append('isReport', true);

          for (let i = 0; i < documents.length; i++) {
            let file = documents[i];
            formData.append('documents[' + i + ']', file);
          }

          store.dispatch('store/addDocument', {meetingId: dataInfo.value.id, dataInfo: formData}).then(response => {
            toastMessage(toast, 'success', response.data.message)
            router.push({name: 'meeting-list'})
          }).catch(error => {
            toastMessage(toast, 'danger', error.response.data.message)
          }).finally(message => {
            busy.value = false
          })

        } else {
          toastMessage(toast, 'success', response.data.message)
          router.push({name: 'meeting-list'})
        }

      }).catch(error => {
        toastMessage(toast, 'danger', error.response.data.message)
      }).finally(message => {
        busy.value = false
      })
    }

    const guestInfo = ref({
      id: 0,
      firstName: '',
      lastName: '',
      title: '',
      company: '',
      phone: '',
      eMail: '',
      isDeleted: false
    })

    const guestModalShow = ref(false)

    const guestNewModal = () => {
      guestInfo.value = {
        id: 0,
        firstName: '',
        lastName: '',
        title: '',
        company: '',
        phone: '',
        eMail: '',
        index: null,
        isDeleted: false
      }
      guestModalShow.value = true
    }

    const guestResetModal = () => {

    }

    const guestSubmitModal = () => {
      dataInfo.value.participants.push({
        meetingParticipantTypeId: 'GUEST',
        userId: null,
        id: guestInfo.value.id,
        firstName: guestInfo.value.firstName,
        lastName: guestInfo.value.lastName,
        fullName: guestInfo.value.firstName + ' ' + guestInfo.value.lastName,
        value: guestInfo.value.firstName + ' ' + guestInfo.value.lastName,
        title: guestInfo.value.title,
        company: guestInfo.value.company,
        phone: guestInfo.value.phone,
        eMail: guestInfo.value.eMail,
        index: Math.floor(Math.random() * 112114115171),
        isDeleted: guestInfo.value.isDeleted,
        participation: true,
      })

      guestInfo.value = {
        id: 0,
        firstName: '',
        lastName: '',
        title: '',
        company: '',
        phone: '',
        eMail: '',
        index: null,
        isDeleted: false
      }
      guestModalShow.value = false
    }

    const guestHandleModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()

      if (guestInfo.value.firstName === '') {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'İsim zorunludur!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      if (guestInfo.value.lastName === '') {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Soyisim zorunludur!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      if (guestInfo.value.title === '') {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Ünvan zorunludur!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      guestSubmitModal()
    }

    const removeParticipant = (participantData) => {
      Vue.swal({
        title: 'Onaylıyor musunuz?',
        text: 'Katılım durumu değiştirilecektir!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, Değiştir',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          dataInfo.value.participants.forEach((value, index) => {
            if (value === participantData) {
              value.participation = !value.participation
            }
          });
        }
      })
    }

    watch(dataInfo, (newValue, oldValue) => {

    });

    const participantUsers = computed(() => dataInfo.value.participants.filter(item => (item.meetingParticipantTypeId === 'PARTICIPANT' || item.meetingParticipantTypeId === 'ORGANIZER') && item.isDeleted === false));
    const informationUsers = computed(() => dataInfo.value.participants.filter(item => item.meetingParticipantTypeId === 'INFORMATION' && item.isDeleted === false));
    const guestUsers = computed(() => dataInfo.value.participants.filter(item => item.meetingParticipantTypeId === 'GUEST' && item.isDeleted === false));

    const addParticipant = (type) => {
      if (newParticipantUserId.value === null && newInfoUserId.value === null) {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Lütfen seçim işlemini yapınız!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      let userId = 0;
      if (type === 'PARTICIPANT') {
        userId = newParticipantUserId.value;
      } else {
        userId = newInfoUserId.value;
      }

      if (dataInfo.value.participants.filter(item => item.meetingParticipantTypeId === type && item.userId === userId && item.isDeleted === false).length === 0) {
        const selectedUser = userOptions.value.filter(item => item.value === userId)[0]
        dataInfo.value.participants.push({
          meetingParticipantTypeId: type,
          userId: userId,
          id: null,
          firstName: selectedUser.firstName,
          lastName: selectedUser.lastName,
          fullName: selectedUser.firstName + ' ' + selectedUser.lastName,
          value: selectedUser.firstName + ' ' + selectedUser.lastName,
          title: selectedUser.positionName,
          profilePicture: selectedUser.avatar,
          company: '',
          phone: '',
          eMail: '',
          index: Math.floor(Math.random() * 112114115171),
          isDeleted: false,
          participation: true,
        })

        newParticipantUserId.value = null
        newInfoUserId.value = null

      } else {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Kayıt daha önce eklenmiş!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
      }
    }

    busy.value = true
    store.dispatch('store/fetchItem', {id: router.currentRoute.params.id}).then(response => {
      dataInfo.value = response.data.data
      editDataForm.value = JSON.parse(localStorage.getItem('userData')).id === dataInfo.value.ownerId && (dataInfo.value.meetingStatusId === 'SHARED' || dataInfo.value.meetingStatusId === 'REPORT_CREATED')

      if (dataInfo.value.reportDescription === null || dataInfo.value.reportDescription === '') {
        dataInfo.value.participants.forEach((value, index) => {
          value.participation = true
        })
      }

      dataInfo.value.flows.forEach((value, index) => {
        value.value = value.firstName + ' ' + value.lastName
        value.userInfo = {
          fullName: value.firstName + ' ' + value.lastName,
          profilePicture: value.profilePicture
        }
      })

    }).catch(error => {
      toastMessage(toast, 'danger', error.response.data.message)
      if (error.response.status === 403) {
        router.push({name: 'meeting-list'})
      }
    }).finally(message => {
      busy.value = false
    })

    const onShare = () => {
      if (dataInfo.value.meetingStatusId === 'CLOSED') {
        Vue.swal({
          icon: 'error',
          title: 'Hata!',
          text: 'Tutanak daha önce paylaşılmış!',
          confirmButtonText: 'Tamam',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        })
        return
      }

      Vue.swal({
        title: 'Onaylıyor musunuz?',
        text: 'Toplantı tutanağı paylaşılacak!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Evet, Paylaş',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch('store/reportShareItem', {id: dataInfo.value.id}).then(response => {
            if (response.data.success) {
              router.push({name: 'meeting-list'})
              Vue.swal({
                icon: 'success',
                title: 'Başarılı!',
                text: 'Tutanak paylaşıldı.',
                confirmButtonText: 'Tamam',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
              })
            }
          }).catch(error => {
            Vue.swal({
              icon: 'error',
              title: 'Hata!',
              text: error.response.data.message,
              confirmButtonText: 'Tamam',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
            })
          })
        }
      })
    }

    const documentHandleModal = (bvModalEvt) => {
      bvModalEvt.preventDefault()
      documentModalShow.value = false
    }

    documentTableColumns.value = [
      {key: 'name', label: 'Dosya Adı', sortable: false},
      {key: 'createdUserName', label: 'Ekleyen', sortable: false},
      {key: 'isReport', label: 'Tutanak', sortable: false},
      {key: 'createdOn', label: 'Tarih', sortable: true},
      {key: 'filePath', label: 'Görüntüle', sortable: false},
    ]

    const previewFile = (filePath) => {
      window.open(getApiFile(filePath), '_blank');
    }

    return {
      busy,
      dataInfo,
      guestModalShow,
      guestInfo,
      userOptions,
      newParticipantUserId,
      newInfoUserId,
      participantUsers,
      informationUsers,
      guestUsers,
      editDataForm,
      documentModalShow,
      documentTableColumns,

      statusOptions,
      yesNoOptions,

      onSubmit,
      guestNewModal,
      guestResetModal,
      guestSubmitModal,
      guestHandleModal,
      removeParticipant,
      avatarText,
      getApiFile,
      addParticipant,
      onShare,
      formatTime,
      documentHandleModal,
      previewFile,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select';
@import '@core/scss/vue/libs/vue-flatpicker';

.select-size-sm {
  min-width: 100%;
}

.empty-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.button-group {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end; /* Opsiyonel, sağa yaslamak için */
}

.scroll-flow {
  max-height: 650px;
  overflow-y: auto;
}

</style>
